import { Navigate, Route, Routes } from "react-router-dom";
import { LandingPage } from "../landing";
import { LoginPage } from "../login";
import { RegisterPage } from "../register";
import { SharePage } from "../share";

export const Path = {
  LANDING: "/",
  SHARE: "/share",
  REGISTER: "/register",
  LOGIN: "/login",
};

const routes = [
  {
    component: <LandingPage />,
    strict: true,
    path: Path.LANDING,
  },
  {
    component: <SharePage />,
    path: Path.SHARE,
  },
  {
    component: <RegisterPage />,
    path: Path.REGISTER,
  },
  {
    component: <LoginPage />,
    path: Path.LOGIN,
  },
];

export const AppRoutes = () => {
  return (
    <Routes>
      {routes.map((r) => (
        <Route
          key={r.path}
          strict={r.strict || false}
          path={r.path}
          element={r.component}
        />
      ))}
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};
