import styles from "./YoutubeVideo.module.scss";

export const YoutubeVideo = (props) => {
  return (
    <div className={styles.responsiveContainer}>
      <iframe
        width={500}
        height={300}
        src={`https://www.youtube.com/embed/${props.videoId}`}
        title="Youtube Player"
        frameBorder={0}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        {...props.extraProps}
      />
    </div>
  );
};
