import { useContext } from "react";
import { UserContext } from "../components/common/UserContext";

const genUrl = (url) => `${process.env.REACT_APP_API_URL}${url}`;

export const useCustomFetch = () => {
  const { userState, setUserState } = useContext(UserContext);

  return async (method, url, options) => {
    const finalOptions = {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(options.body),
    };

    if (userState?.token) {
      finalOptions.headers["Authorization"] = `Bearer ${userState.token}`;
    }

    const res = await fetch(genUrl(url), finalOptions);

    const parsed = await res.json();

    if (res.status === 401) {
      setUserState(undefined);
    } else if (!res.ok) {
      throw new Error(parsed.message);
    }

    return parsed;
  };
};
