import { useMemo } from "react";

const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

export const useCredentialValidator = (email = "", password = "") => {
  const emailValid = useMemo(() => !!email.match(emailRegex), [email]);
  const passwordValid = useMemo(
    () => !!password.match(passwordRegex),
    [password]
  );

  return {
    emailValid,
    passwordValid,
  };
};
