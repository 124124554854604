import { Header } from "./components/common/Header";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./components/common/AppRoutes";
import { InformativeModalContext } from "./components/common/InformativeModal";
import { UserContextWrapper } from "./components/common/UserContext";

export const App = () => {
  return (
    <BrowserRouter>
      <UserContextWrapper>
        <InformativeModalContext>
          <Header />
          <AppRoutes />
        </InformativeModalContext>
      </UserContextWrapper>
    </BrowserRouter>
  );
};
