import { useContext } from "react";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { LoadingButton } from "./LoadingButton";
import { UserContext } from "./UserContext";

export const LikeButton = (props) => {
  const { dislike, selected, count, ...extraProps } = props;
  const { userState } = useContext(UserContext);

  const IconComponent = dislike ? FaThumbsDown : FaThumbsUp;

  const color = dislike ? "danger" : "primary";

  return userState ? (
    <LoadingButton
      {...extraProps}
      variant={selected ? color : `outline-${color}`}
    >
      <div className="d-flex align-items-center">
        <IconComponent />
        <div className="ms-2">{count}</div>
      </div>
    </LoadingButton>
  ) : (
    <div className={`d-inline-flex align-items-center text-${color} me-4`}>
      <IconComponent />
      <div className="ms-2">{count}</div>
    </div>
  );
};
