import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Container, Navbar } from "react-bootstrap";
import { UserContext } from "./UserContext";
import { useApis } from "../../hooks/useApis";

export const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { userState } = useContext(UserContext);
  const { logout } = useApis();

  const onRedirect = (path) => () => {
    if (pathname === "/") {
      scrollToTop();
    } else {
      navigate(path);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Navbar bg="light" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand
          style={{ cursor: "pointer" }}
          onClick={onRedirect("/home")}
        >
          Funny Movies
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          {userState ? (
            <>
              <Navbar.Text>{`Welcome ${userState.email}`}</Navbar.Text>
              <Button className="mx-3" onClick={() => navigate("/share")}>
                Share a movie
              </Button>
              <Button variant="danger" onClick={() => logout()}>
                Logout
              </Button>
            </>
          ) : (
            <>
              <Button className="mx-3" onClick={() => navigate("/login")}>
                Login
              </Button>
              <Button variant="secondary" onClick={() => navigate("/register")}>
                Register
              </Button>
            </>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
