import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useApis } from "../../hooks/useApis";
import { UserContext } from "../common/UserContext";
import { MovieTile } from "../landing/MovieTile";

export const LandingPage = () => {
  const [vids, setVids] = useState([]);
  const { userState } = useContext(UserContext);
  const { getVideos } = useApis();

  useEffect(() => {
    (async () => {
      setVids(await getVideos());
    })();
  }, [userState]);

  return (
    <Container fluid="md">
      <Row>
        {vids.map((m, i) => (
          <Col md={12} key={`${i}-${m.title}`}>
            <MovieTile {...m} setVids={setVids} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
