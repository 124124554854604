import React from "react";
import {
  ModalContext,
  ModalState,
} from "../components/common/InformativeModal";
import { UserContext } from "../components/common/UserContext";
import { useCustomFetch } from "./useCustomFetch";

export const useApis = () => {
  const { setModalState } = React.useContext(ModalContext);
  const { userState, setUserState } = React.useContext(UserContext);
  const customFetch = useCustomFetch();

  const register = async (email, password) => {
    const res = await customFetch("POST", "/register", {
      body: { email, password },
    });

    setUserState({
      email: res.email,
      token: res.token,
    });
    setModalState(ModalState.REGISTER_SUCCESS);
  };

  const login = async (email, password) => {
    const res = await customFetch("POST", "/login", {
      body: { email, password },
    });

    setUserState({
      email: res.email,
      token: res.token,
    });
    setModalState(ModalState.LOGIN_SUCCESS);
  };

  const logout = () => {
    setUserState(undefined);
    setModalState(ModalState.LOGOUT_SUCCESS);
  };

  const share = async (videoId) => {
    await customFetch("POST", "/share", {
      body: { videoId },
    });

    setModalState(ModalState.SHARE_SUCCESS);
  };

  const getVideos = async () => {
    const { videos } = await customFetch("GET", "/videos", {
      token: userState?.token,
    });

    return videos;
  };

  const like = async (videoId) => {
    const { videos } = await customFetch("POST", "/like", {
      body: { videoId },
    });

    return videos;
  };

  const dislike = async (videoId) => {
    const { videos } = await customFetch("POST", "/dislike", {
      body: { videoId },
    });

    return videos;
  };

  return {
    login,
    logout,
    register,
    share,
    getVideos,
    like,
    dislike,
  };
};
