import { createContext, useEffect, useState } from "react";

const UserLocalStorageKey = "USER_CREDS";
export const UserContext = createContext();

export const UserContextWrapper = (props) => {
  const [userState, setUserState] = useState(undefined);

  useEffect(() => {
    const savedUser = window.localStorage.getItem(UserLocalStorageKey);

    if (savedUser) {
      setUserState(JSON.parse(savedUser));
    }
  }, []);

  useEffect(() => {
    if (!userState) {
      window.localStorage.removeItem(UserLocalStorageKey);
    } else {
      window.localStorage.setItem(
        UserLocalStorageKey,
        JSON.stringify(userState)
      );
    }
  }, [userState]);

  return (
    <UserContext.Provider value={{ userState, setUserState }}>
      {props.children}
    </UserContext.Provider>
  );
};
