import { Spinner, Button } from "react-bootstrap";

export const LoadingButton = (props) => {
  const { children, disabled, loading, ...extraProps } = props;

  return (
    <Button disabled={disabled || loading} {...extraProps}>
      {loading ? (
        <Spinner animation="border" variant="light" size="sm" />
      ) : (
        children
      )}
    </Button>
  );
};
