import { useContext, useEffect, useState } from "react";
import { Card, Container, Form } from "react-bootstrap";
import { useApis } from "../../hooks/useApis";
import { useYoutubeValidator } from "../../hooks/useYoutubeValidator";
import { ModalContext, ModalState } from "../common/InformativeModal";
import { LoadingButton } from "../common/LoadingButton";

export const SharePage = () => {
  const [url, setUrl] = useState("");
  const [dirty, setDirty] = useState(false);
  const { valid, videoId } = useYoutubeValidator(url);
  const { setModalState } = useContext(ModalContext);
  const [loading, setLoading] = useState(false);
  const { share } = useApis();

  useEffect(() => {
    (async () => {
      if (loading) {
        try {
          await share(videoId);
          setUrl("");
          setDirty(false);
        } catch (e) {
          setModalState(ModalState.FAIL(e.message));
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [loading]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (valid) {
      setLoading(true);
    }
  };

  const onInputChange = (e) => {
    setDirty(true);
    setUrl(e.target.value);
  };

  return (
    <Container className="py-5">
      <Card>
        <Card.Body>
          <Card.Title>Share a Youtube movie</Card.Title>
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Youtube URL</Form.Label>
              <Form.Control
                value={url}
                onChange={onInputChange}
                type="url"
                placeholder="Enter Youtube URL (e.g. https://www.youtube.com/watch?v=vmODd42hMV4)"
              />
              {dirty && !valid && (
                <Form.Text className="text-danger">
                  Please enter a valid youtube URL
                </Form.Text>
              )}
            </Form.Group>

            <LoadingButton disabled={!valid} loading={loading} type="submit">
              Share
            </LoadingButton>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};
