import { createContext, useState } from "react";
import { Modal } from "react-bootstrap";

export const ModalState = {
  CLOSE: {},
  LOGIN_SUCCESS: {
    title: "Hooray!",
    body: "Welcome back!",
  },
  LOGOUT_SUCCESS: {
    title: "Hooray!",
    body: "See you again soon.",
  },
  REGISTER_SUCCESS: {
    title: "Hooray!",
    body: "Registration complete!",
  },
  SHARE_SUCCESS: {
    title: "Hooray!",
    body: "Now your fellow FunnyMoviers can enjoy your awesome video.",
  },
  FAIL: (message) => ({
    title: "Oops...",
    body: message || "There's something wrong. Please try again later!",
  }),
};

export const InformativeModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.type.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.type.body}</Modal.Body>
    </Modal>
  );
};

export const ModalContext = createContext();

export const InformativeModalContext = (props) => {
  const [modalState, setModalState] = useState(ModalState.CLOSE);

  const handleClose = () => {
    setModalState(ModalState.CLOSE);
  };

  return (
    <ModalContext.Provider value={{ modalState, setModalState }}>
      <InformativeModal
        type={modalState}
        show={modalState !== ModalState.CLOSE}
        handleClose={handleClose}
      />
      {props.children}
    </ModalContext.Provider>
  );
};
