import { useEffect, useState } from "react";

export const useYoutubeValidator = (url = "") => {
  const [valid, setValid] = useState(false);
  const [videoId, setVideoId] = useState("");

  useEffect(() => {
    if (!url || url === "") {
      setValid(false);
    } else {
      // regex from https://stackoverflow.com/questions/28735459/how-to-validate-youtube-url-in-client-side-in-text-box
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      const match = url.match(regExp);
      if (match && match[2].length === 11) {
        setValid(true);
        setVideoId(match[2]);
      } else {
        setValid(false);
      }
    }
  }, [url]);

  return { valid, videoId };
};
