import { useContext, useEffect, useState } from "react";
import { Card, Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useApis } from "../../hooks/useApis";
import { ModalContext, ModalState } from "../common/InformativeModal";
import { LoadingButton } from "../common/LoadingButton";

export const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setModalState } = useContext(ModalContext);
  const [loading, setLoading] = useState(false);
  const { login } = useApis();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (loading) {
        try {
          await login(email, password);
          navigate("/");
        } catch (e) {
          setModalState(ModalState.FAIL(e.message));
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [loading]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  };

  const onInputChange = (onChange) => (e) => {
    onChange(e.target.value);
  };

  return (
    <Container className="my-5">
      <Card>
        <Card.Body>
          <Card.Title>Login</Card.Title>
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                disabled={loading}
                value={email}
                onChange={onInputChange(setEmail)}
                type="email"
                placeholder="example@something.com"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                disabled={loading}
                value={password}
                onChange={onInputChange(setPassword)}
                type="password"
              />
            </Form.Group>

            <LoadingButton disabled={loading} loading={loading} type="submit">
              Login
            </LoadingButton>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};
