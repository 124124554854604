import { Col, Container, Row } from "react-bootstrap";
import { YoutubeVideo } from "./YoutubeVideo";
import { LikeButton } from "../common/LikeButton";
import { useApis } from "../../hooks/useApis";
import { useEffect, useState } from "react";

export const MovieTile = (props) => {
  const {
    title,
    sharedBy,
    likeCnt,
    dislikeCnt,
    description,
    videoId,
    liked,
    disliked,
    setVids,
  } = props;
  const { like, dislike } = useApis();
  const [loading, setLoading] = useState(undefined);

  useEffect(() => {
    (async () => {
      if (loading === "like") {
        setVids(await like(videoId));
      } else if (loading === "dislike") {
        setVids(await dislike(videoId));
      }

      setLoading(undefined);
    })();
  }, [loading]);

  const onLike = async () => {
    setLoading("like");
  };

  const onDislike = async () => {
    setLoading("dislike");
  };

  return (
    <Container className="py-5">
      <Row>
        <Col md={5}>
          <YoutubeVideo videoId={videoId} />
        </Col>
        <Col md={7}>
          <Row>
            <Col md={12}>
              <h2>{title}</h2>
            </Col>
            <Col md={12}>
              <p>Shared by: {sharedBy}</p>
            </Col>
            <Col md={12}>
              <LikeButton
                selected={liked}
                count={likeCnt}
                onClick={onLike}
                loading={!!loading}
              />
              <LikeButton
                className="ms-2"
                dislike
                selected={disliked}
                count={dislikeCnt}
                onClick={onDislike}
                loading={!!loading}
              />
            </Col>
            <Col md={12}>
              <p>{description}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
