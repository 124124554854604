import { useContext, useEffect, useState } from "react";
import { Alert, Card, Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useApis } from "../../hooks/useApis";
import { useCredentialValidator } from "../../hooks/useCredentialValidator";
import { ModalContext, ModalState } from "../common/InformativeModal";
import { LoadingButton } from "../common/LoadingButton";

export const RegisterPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { emailValid, passwordValid } = useCredentialValidator(email, password);
  const { setModalState } = useContext(ModalContext);
  const { register } = useApis();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validForm = emailValid && passwordValid && password === confirmPassword;

  useEffect(() => {
    (async () => {
      if (loading) {
        try {
          await register(email, password);
          navigate("/");
        } catch (e) {
          setModalState(ModalState.FAIL(e.message));
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [loading]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validForm) {
      setLoading(true);
    }
  };

  const onInputChange = (onChange) => (e) => {
    onChange(e.target.value);
  };

  return (
    <Container className="my-5">
      {!emailValid || !passwordValid ? (
        <Alert variant="warning">
          <Alert.Heading>Please follow these guidelines:</Alert.Heading>
          <ul>
            {!emailValid && (
              <li>Your email must be valid (e.g.: example@something.com)</li>
            )}
            {!passwordValid && (
              <li>
                Your password must has: at least 8 characters, an uppercase
                character, a lowercase character, and a number
              </li>
            )}
            {password !== confirmPassword && (
              <li>Your password and confirm password must match</li>
            )}
          </ul>
        </Alert>
      ) : (
        <Alert variant="success">
          Your credentials are all set! Click register whenever you're ready!
        </Alert>
      )}
      <Card>
        <Card.Body>
          <Card.Title>Register</Card.Title>
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                disabled={loading}
                value={email}
                onChange={onInputChange(setEmail)}
                type="email"
                placeholder="example@something.com"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                disabled={loading}
                value={password}
                onChange={onInputChange(setPassword)}
                type="password"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                disabled={loading}
                value={confirmPassword}
                onChange={onInputChange(setConfirmPassword)}
                type="password"
              />
            </Form.Group>

            <LoadingButton
              disabled={!validForm}
              loading={loading}
              type="submit"
            >
              Register
            </LoadingButton>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};
